* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: rgb(146, 142, 142);

}

html {
    font-size: 62.5%;
}

.container {


    height: 79rem;
    width: 52.5%;

    margin: 7.5rem auto;
}

.main-div {

    display: flex;

    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #ffffffe0;
    width: 100rem;
    height: 78rem;
    border-radius: .8rem;
    box-shadow: .5rem .5rem .5rem .5rem grey;

}

.child-div {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 1.5rem;
    height: 55rem;
 padding-top: 1rem;
    border-top: 1px solid grey;
   
}

.img_contain {
    margin-top: 5rem;
}

img{
    position: relative;
    bottom: 1.5rem;
    height: 12rem;
    width: 15%;
    border: 1px solid #ddd;
    padding: 5px;
 border-radius: .4rem;
 box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 14px 0 rgba(0, 0, 0, 0.19);
}


.child-div figcaption {
    padding-top: 1.5rem;
    font-size: 3rem;


}


 .fa-edit {
    margin-right: 1.4rem;
    color: #0000ff9c;
    font-size: 2.5rem;
    position: relative;
    bottom: .3rem;
}

.fa-edit:hover {
    color: blue;
}

.fa-plus {
    position: relative;
    right: 2.9rem;
    font-size: 2.2rem;
    padding-bottom: .9rem;
}

.fa-plus:hover {
    color: rgb(0, 128, 107);
}

input[type=text] {
    font-size: 3rem;
    border-radius: .4rem;
    border: none;
    padding: .5rem;
}


.display-item {

    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;
    overflow-y: scroll;

    margin: 1rem auto;
    width: 80%;

}

.show-items {

    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 30rem;
    height: 4rem;
    margin: .8rem 0;
    background-color: white;
    filter: drop-shadow(0.2rem 0.3rem 0.2rem grey);
    border-radius: 0.3rem;

    font-size: 3rem;

    padding: 0 10px;

}

.show-items h4 {

    font-weight: 500;
    font-size: 2rem;
}

.fa-solid {
   position: relative;
  
bottom: .3rem;
    font-size: 2.1rem;
}

.fa-solid:hover {
    color: red;
}

.btn {
    margin: 18px 0 0 0;
}

.sm-btn {

    padding: 6px 21px;
    border-radius: 0.6rem;
    cursor: pointer;
    font-size: 1.8rem;
    background-color: red;
    color: white;
    font-weight: 400;
    margin: .7rem 0 2.4rem 0;
    border: none;

}

.sm-btn:hover {
    background-color: #ff0000c4;

}


@media (max-width: 2094px) {
    .container {
        margin: 7.5rem auto
    }
}

@media (max-width: 2200px) {
    .container {
        margin: 7.5rem auto;
    }

}



